import {IconButton, Typography, alpha} from "@mui/material";
import {MyLocation} from "@mui/icons-material";
import {HOTSPOT_DESELECTED, HOTSPOT_SELECTED, useMapDispatch, useMapState} from "./mapContext";
import {MAP_DESELECTED, SET_ACTIVE_DOME, useThreeSixtyDispatch, useThreeSixtyProps} from "../App/ThreeSixtyContext";

const maxSize = 3.8
const minSize = 1.3
const minValue = 0
const maxValue = 100

export const MapHotspot = ({iconSize, hotspot}) => {
    const {adminMode} = useThreeSixtyProps()
    const mapDispatch = useMapDispatch()
    const {activeHotspotId} = useMapState()
    const dispatch = useThreeSixtyDispatch()

    const normalizedValue = (iconSize - minValue) / (maxValue - minValue)
    const sizeInRem = minSize + (maxSize - minSize) * normalizedValue

    const handleClick = () => {
        if(adminMode) {
            mapDispatch({
                type: HOTSPOT_SELECTED,
                hotspotId: hotspot.id
            })
        } else {
            dispatch({
                type: SET_ACTIVE_DOME,
                domeId: hotspot.destination
            })
            dispatch({type: MAP_DESELECTED})
        }
    }

    const handleDragStart = (e) => {
        if (activeHotspotId !== hotspot.id) {
            mapDispatch({
                type: HOTSPOT_DESELECTED
            })
        }
        e.dataTransfer.setData("text/plain", hotspot.id);
        e.dataTransfer.effectAllowed = 'move'
    }

    return (
        <>
            <IconButton
                onClick={handleClick}
                draggable={adminMode}
                onDragStart={adminMode ? handleDragStart : null}
                sx={{
                    position: "absolute",
                    left: `${hotspot.x}%`,
                    top: `${hotspot.y}%`,
                    transform: 'translate(-50%, -50%)',
                    border: "white 1px solid",
                    backgroundColor: activeHotspotId === hotspot.id ? theme => alpha(theme.palette.primary.light, 0.7) : "rgba(0, 0, 0, 0.7)",
                    "&:hover": {
                        backgroundColor: theme => alpha(theme.palette.primary.main, 0.7)
                    }
                }}
            >
                <MyLocation
                    sx={{
                        fontSize: `${sizeInRem}rem`,
                        color: "white"
                    }}
                />
            </IconButton>

            <Typography
                noWrap={true}
                sx={{
                    position: "absolute",
                    left: `${hotspot.x}%`,
                    top: `${hotspot.y}%`,
                    transform: 'translate(-50%, -50%)',
                    color: "white",
                    fontSize: "16px",
                    mt: `${Math.min(3.2, Math.max(2, sizeInRem))}rem`,
                    textShadow: "0px 2px 3px rgba(0, 0, 0, 1), 0px 0px 3px rgba(0, 0, 0, 1), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
            >
                {hotspot.label}
            </Typography>
        </>
    )
}